
import api from '@/api/co.api'
import app from '@/api/co.app'
// import lib from '@/api/co.lib'
import act from '@/api/co.action'

import notice from '@/api/co.notice'
import survey from '@/api/co.survey'

import mixPreset from '@/pages/mixins/home/mix.home.preset'

const preNames = mixPreset.Names
const preParts = mixPreset.Parts

export default {
  data () {
    return {
      dbMain: {
        active: preNames.LASTEST,
        tabs: [
          preParts[preNames.LASTEST],
          preParts[preNames.INFORM]
        ]
      },
      dbSide: {
        vote: {
          name: survey.comm.Types.VOTE,
          type: survey.comm.Types.VOTE,
          limit: '1',
          params: null,
          items: [],
          init: this.initVoteParams
        },
        video: {
          name: preNames.VIDEO,
          title: '园区风貌',
          type: 'notice_video',
          topicId: '',
          categoryId: '',
          href: '',
          limit: 1,
          params: null,
          items: [],
          init: this.initVideoParams
        }
      },
      currPlayer: null,
    }
  },
  beforeDestroy () {
    if (this.currPlayer) {
      this.currPlayer.pause()
    }
  },
  methods: {
    initVoteParams (obj) {
      const executed = function (res) {
      }

      obj.params = survey.main.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        status: 'publish',
        limit: obj.limit || 1,
        caches: obj.items,
        executed
      })
    },
    initVideoParams (obj) {
      const executed = function (res) {
        // console.log('obj.items: %o', obj.items)
        if (res.status) {
          for (const i in obj.items) {
            const item = obj.items[i]
            const options = api.comm.deepClone(mixPreset.PlayerConfig)
            const linkex = item.linkex || {}
            options.poster = item.cover
            options.sources = [{
              type: linkex.type || 'video/mp4',
              src: linkex.path || ''
            }]
            item.options = options
            item.name = `ref_vid_${i}`
          }
        }
      }

      obj.params = notice.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        topicId: obj.topicId,
        status: 'publish',
        limit: obj.limit || 1,
        caches: obj.items,
        executed
      })
    },
    onProblem (ev) {
      act.navigate({ that: this, item: ev.item })
    },
    onNoticeMore (ev, tabName) {
      let nav = null
      for (const i in this.dbMain.tabs) {
        const item = this.dbMain.tabs[i]
        if (item.name === tabName) {
          nav = item
          break
        }
      }

      if (nav) {
        this.toNotices(ev, nav)
      }
    },
    onNotice (ev, category) {
      act.navigate({
        that: this,
        pagename: mixPreset.DetailName,
        item: ev.item,
        category
      })
    },
    toNotices (ev, category) {
      act.navigate({
        that: this,
        pagename: mixPreset.ListName,
        item: {
          type: 'notices'
        },
        category
      })
    },
    onVideoPlay (player) {
      // console.log('mix.park.video onVideoPlay player: %o', player)
      // const name = `ref_vid_${index}`
      this.currPlayer = player
    },
    initParamsBy (category = null) {
      // const me = this
      if (!category) {
        return
      }
      const executing = function () {
      }

      const executed = function (res) {
        // console.log('res: %o', res)
      }
      category.params = notice.getParams({
        siteId: app.init.siteId,
        type: category.type,
        topicId: category.topicId,
        limit: '7',
        caches: category.items,
        executing,
        executed
      })
    },
    initParams () {
      for (const i in this.dbMain.tabs) {
        const item = this.dbMain.tabs[i]
        this.initParamsBy(item)
      }

      for (const key in this.dbSide) {
        const item = this.dbSide[key]
        if (typeof item.init === 'function') {
          item.init(item)
        }
      }
    },
    loadItems (obj, reload = true) {
      const params = obj.params
      if (params) {
        params.reload = reload
        api.http.getItems(params)
      }
    },
    loadData (reload = true) {
      const items = this.dbMain.tabs
      for (const i in items) {
        const item = items[i]
        this.loadItems(item, reload)
      }

      for (const key in this.dbSide) {
        const item = this.dbSide[key]
        this.loadItems(item, reload)
      }
    },
  }
}
